import React from 'react';
import CustomDialogComponent from '../../../../../../commons/components/custom-dialog/custom-dialog.component';
import { Button, Grid, LinearProgress, MenuItem, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { DateTimeUtils } from '../../../../../../commons/utils/date-time.utils';
import logger from '../../../../../../commons/logger';
import adminService from '../../../../../../services/admin.service';
import { DATE_FORMAT } from '../../../../../../models/enums/DateFormat.enum';
import { BusinessObjectStatistic } from '../../../../../../models/interfaces/BusinessObjectStatistic.interface';
import { Chart } from 'chart.js';
import { ChartJsDataSet } from '../../../../../../models/interfaces/ChartJsDataSet.interface';
import chartJsLineChartService from '../../../../../../services/chart-js-line-chart.service';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { RANGE_VIEW_MODE } from '../../../../../../models/enums/RangeViewMode.enum';
import { DateRange } from '../../../../../../models/interfaces/DateRange.interface';
import { RangeViewModeUtils } from '../../../../../../commons/utils/RangeViewMode.utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartWrapper: {
      marginTop: '20px',
      minHeight: '400px',
      maxHeight: '500px',
      width: '80vw',
    },
  })
);

interface BusinessObjectStatisticDetailsDialogComponentProps {
  boId: number;
  boName: string;
  owner: string;
  isOpen: boolean;
  onClose: Function;
}

export default function BusinessObjectStatisticDetailsDialogComponent(
  props: BusinessObjectStatisticDetailsDialogComponentProps
) {
  const [viewMode, setViewMode] = React.useState<RANGE_VIEW_MODE>(null);
  const [dateRange, setDateRange] = React.useState<DateRange>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [myChart, setMyChart] = React.useState<Chart>(undefined);

  const VIEW_MODE_OPTIONS = [RANGE_VIEW_MODE.DAY, RANGE_VIEW_MODE.MONTH];
  const CHART_ID = 'BusinessObjectStatisticDetailsDialogComponent';

  const fetchDataAndRerenderChart = async () => {
    if (!viewMode || !dateRange?.fromDate || !dateRange?.toDate) {
      return;
    }

    setIsLoading(true);

    try {
      // fetch data
      let dateRangeList: DateRange[];

      if (viewMode === RANGE_VIEW_MODE.DAY) {
        // generate 7 ranges for 7 days in dateRange
        dateRangeList = DateTimeUtils.getListOfDate(dateRange.fromDate, dateRange.toDate)?.map(d => ({
          fromDate: d,
          toDate: d,
        }));
      } else if (viewMode === RANGE_VIEW_MODE.MONTH) {
        // generate 12 ranges for 12 months in dateRange
        const listOfMonths: Date[] = DateTimeUtils.getListOfMonth(dateRange.fromDate, dateRange.toDate);
        dateRangeList = listOfMonths?.map(d => ({
          fromDate: DateTimeUtils.getFirstDateOfMonth(d),
          toDate: DateTimeUtils.getLastDateOfMonth(d),
        }));
      }

      const dataList = await Promise.all(
        dateRangeList?.map(r =>
          adminService.getBusinessObjectStatisticForBo(
            DateTimeUtils.formatDateToString(r.fromDate, DATE_FORMAT.DATE_FORMAT_BACKEND),
            DateTimeUtils.formatDateToString(r.toDate, DATE_FORMAT.DATE_FORMAT_BACKEND),
            props.boId
          )
        )
      );

      let matchedData: BusinessObjectStatistic[] = [];
      dataList?.forEach(data => {
        matchedData = [...matchedData, data];
      });

      matchedData.sort((a, b) => a.from.localeCompare(b.from));

      // generated chart
      const numberOfSentSmsData: ChartJsDataSet = {
        label: 'Nr sent SMS',
        backgroundColor: 'blue',
        borderColor: 'blue',
        data: [],
      };
      const numberOfSentEmailData: ChartJsDataSet = {
        label: 'Nr sent Email',
        backgroundColor: 'green',
        borderColor: 'green',
        data: [],
      };
      const numberOfAppointmentData: ChartJsDataSet = {
        label: 'Nr appointments',
        backgroundColor: 'yellow',
        borderColor: 'yellow',
        data: [],
      };
      const numberOfOnlineBPAppointments: ChartJsDataSet = {
        label: 'Nr Online BP appointments',
        backgroundColor: 'yellow',
        borderColor: 'darkorange',
        data: [],
      };
      const numberOfNewCustomerData: ChartJsDataSet = {
        label: 'Nr new customers',
        backgroundColor: 'red',
        borderColor: 'red',
        data: [],
      };
      const numberOfOrderData: ChartJsDataSet = {
        label: 'Nr orders',
        backgroundColor: 'purple',
        borderColor: 'purple',
        data: [],
      };
      const labels: string[] = [];

      matchedData?.forEach(d => {
        numberOfSentSmsData.data.push(d.numberOfSentSms);
        numberOfSentEmailData.data.push(d.numberOfSentEmail);
        numberOfAppointmentData.data.push(d.numberOfAppointments);
        numberOfOnlineBPAppointments.data.push(d.numberOfOnlineBPAppointments);
        numberOfNewCustomerData.data.push(d.numberOfNewCustomers);
        numberOfOrderData.data.push(d.numberOfOrders);
        labels.push(viewMode === RANGE_VIEW_MODE.DAY ? d.from : d.from.slice(0, -3));
      });

      const chartJsDataSet: ChartJsDataSet[] = [
        numberOfSentSmsData,
        numberOfSentEmailData,
        numberOfAppointmentData,
        numberOfOnlineBPAppointments,
        numberOfNewCustomerData,
        numberOfOrderData,
      ];

      const ctx = document.getElementById(CHART_ID);

      if (ctx) {
        const chartConfig = chartJsLineChartService.generateMultiLinesChartOption(chartJsDataSet, labels);

        if (myChart) {
          myChart.destroy();
        }

        setMyChart(new Chart(ctx as HTMLCanvasElement, chartConfig));
      } else {
        logger.debug(`ERROR: not found id=${CHART_ID}`);
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateDateRangeOnViewModeChange = (selectedViewMode: RANGE_VIEW_MODE) => {
    setDateRange(RangeViewModeUtils.generateEarliestDateRangeFromViewMode(selectedViewMode));
  };

  const changeTimeRangeWindow = (isNext: boolean) => {
    const newDateRange: DateRange = isNext
      ? RangeViewModeUtils.getNextDateRange(dateRange, viewMode)
      : RangeViewModeUtils.getPreviousDateRange(dateRange, viewMode);
    setDateRange(newDateRange);
  };

  const previousClickHandler = () => {
    changeTimeRangeWindow(false);
  };

  const currentClickHandler = () => {
    updateDateRangeOnViewModeChange(viewMode);
  };

  const nextClickHandler = () => {
    changeTimeRangeWindow(true);
  };

  const updateViewModeHandler = (event: any) => {
    const { value } = event.target;
    setViewMode(value);
  };

  // use this syntax to trigger function on init
  React.useEffect(() => {
    setViewMode(RANGE_VIEW_MODE.DAY);
  }, []);

  // use this syntax to watch change of range
  React.useEffect(() => {
    updateDateRangeOnViewModeChange(viewMode);
  }, [viewMode]);

  // use this syntax to watch change of range
  React.useEffect(() => {
    fetchDataAndRerenderChart();
  }, [dateRange]);

  const classes = useStyles();

  const getTitle = () => {
    return `[${props.boId}] ${props.boName} [${props.owner}]`;
  };

  const getContent = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <IconButton
              aria-controls="customized-menu"
              aria-haspopup="true"
              color="primary"
              disabled={isLoading}
              onClick={previousClickHandler}
            >
              <ArrowBackIosIcon />
            </IconButton>

            <Button color="primary" disabled={isLoading} onClick={currentClickHandler}>
              {viewMode === RANGE_VIEW_MODE.DAY ? 'Today' : 'This month'}
            </Button>

            <IconButton
              aria-controls="customized-menu"
              aria-haspopup="true"
              color="primary"
              disabled={isLoading}
              onClick={nextClickHandler}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="dense"
              select
              required
              fullWidth
              label="View mode"
              name="viewMode"
              value={viewMode || ''}
              disabled={isLoading}
              onChange={updateViewModeHandler}
            >
              {VIEW_MODE_OPTIONS.map(m => (
                <MenuItem value={m} key={m}>
                  {m}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        {isLoading ? <LinearProgress /> : <></>}

        <div className={classes.chartWrapper}>
          <canvas id={CHART_ID}></canvas>
        </div>
      </>
    );
  };

  const getButtons = () => {
    return (
      <Button fullWidth variant="contained" onClick={() => props.onClose()}>
        Close
      </Button>
    );
  };

  return (
    <CustomDialogComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={getTitle()}
      content={getContent()}
      buttons={getButtons()}
    ></CustomDialogComponent>
  );
}
