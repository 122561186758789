import React from 'react';
import { BusinessObjectStatistic } from '../../../../models/interfaces/BusinessObjectStatistic.interface';
import { DateTimeUtils } from '../../../../commons/utils/date-time.utils';
import adminService from '../../../../services/admin.service';
import { DATE_FORMAT } from '../../../../models/enums/DateFormat.enum';
import {
  CustomDataTableConfig,
  HeadCell,
} from '../../../../commons/components/custom-data-table/custom-data-table-config.model';
import CustomDataTableComponent from '../../../../commons/components/custom-data-table/custom-data-table.component';
import { Grid, LinearProgress, MenuItem, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import BusinessObjectStatisticDetailsDialogComponent from './components/business-object-statistic-details-dialog/business-object-statistic-details-dialog.component';
import logger from '../../../../commons/logger';
import { BusinessObject } from '../../../../models/interfaces/BusinessObject.interface';
import businessObjectService from '../../../../services/business-object.service';
import { BUSINESS_OBJECT_VERSION } from '../../../../models/enums/BusinessObjectVersion.enum';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginBottom: {
      marginBottom: '20px',
    },
  })
);

const OWNER_TEST: string[] = [
  'one@testusers.com',
  'tunggad.vu@googlemail.com',
  'hovietanh.1991@gmail.com',
  'linhlinhlinh0611991@gmail.com',
  'son87.lengoc@gmail.com',
];

const OWNER_WITH_NEW_PRICE: string[] = [
  '2016winterthur2016@gmail.com',
  'vu-tien@hotmail.com',
  'nailsbeautykarben@gmail.com',
  'heinze.lashes@gmail.com',
  'danganh05012018@gmail.com',
  'tanguyvu72@gmail.com',
  'Longky@gmx.de',
  'stellakoehler13@gmail.com',
  'toninails290821@gmail.com',
];

const LAST_BO_ID_OLD_PRO_PRICE: number = 89;
const LAST_BO_ID_FREE_LITE_PRICE: number = 83;
const LAST_BO_ID_TWO_YEARS_FREE_LITE_PRICE: number = 89; // Begin: 01.04.2024 - End: 31.03.2026

export default function StatisticComponent(props: any) {
  const headCells: HeadCell[] = [
    { id: 'boId', field: 'boId', label: 'Salon ID' },
    { id: 'name', field: 'name', label: 'Salon name' },
    { id: 'owner', field: 'owner', label: 'Owner' },
    { id: 'numberOfSentSms', field: 'numberOfSentSms', label: 'Nr sent SMS' },
    { id: 'numberOfSentEmail', field: 'numberOfSentEmail', label: 'Nr sent Email' },
    { id: 'numberOfAppointments', field: 'numberOfAppointments', label: 'Nr appointments' },
    { id: 'numberOfOnlineBPAppointments', field: 'numberOfOnlineBPAppointments', label: 'Nr Online BP appointments' },
    { id: 'numberOfNewCustomers', field: 'numberOfNewCustomers', label: 'Nr new customers' },
    { id: 'numberOfOrders', field: 'numberOfOrders', label: 'Nr orders' },
    { id: 'monthlyFee', field: 'monthlyFee', label: 'Monthly fee' },
    { id: 'priceDescription', field: 'priceDescription', label: 'Price description' },
  ];

  const tableConfig: CustomDataTableConfig = {
    tableName: `Business object statistic`,
    rowsPerPage: 10,
    headCells,
    highlightRowColorFunc: (item: BusinessObjectStatistic): string | null => {
      if (item?.monthlyFee > 0) {
        return '#72E1A3';
      }

      return null;
    },
  };

  const MONTH_RANGES = [3, 6, 12];

  const [businessObjectStatistics, setBusinessObjectStatistics] = React.useState<BusinessObjectStatistic[]>([]);
  const [filteredBusinessObjectStatistics, setFilteredBusinessObjectStatistics] = React.useState<
    BusinessObjectStatistic[]
  >([]);
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const [range, setRange] = React.useState<number>(3);
  const [isOpenDetailsDialog, setIsOpenDetailsDialog] = React.useState<boolean>(false);
  const [selectedBusinessObject, setSelectedBusinessObject] = React.useState<BusinessObjectStatistic>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const calculateLiteFee = (data: BusinessObjectStatistic, monthRange: number): number => {
    const averageNrOfMonthlyBPAppointment = data?.numberOfOnlineBPAppointments / monthRange;

    if (averageNrOfMonthlyBPAppointment < 10) {
      return 0;
    }

    if (averageNrOfMonthlyBPAppointment < 20) {
      return 10;
    }

    if (averageNrOfMonthlyBPAppointment < 40) {
      return 20;
    }

    if (averageNrOfMonthlyBPAppointment < 70) {
      return 40;
    }

    if (averageNrOfMonthlyBPAppointment < 110) {
      return 70;
    }

    return 100;
  };

  const addPriceDescription = (
    data: BusinessObjectStatistic[],
    businessObjects: BusinessObject[],
    monthRange: number
  ): BusinessObjectStatistic[] => {
    const endDateOfFreeTrial = DateTimeUtils.getEndOfDate(new Date(2026, 2, 31));
    const now = new Date();
    const isFreeTrialActive: boolean = now < endDateOfFreeTrial;

    const PRO_FEE = 30;
    const TW_CONNECT_FEE = 20;

    const OLD_PRO_FEE = 18.95;
    const OLD_LITE_FEE = 10;
    const OLD_LITE_PLUS_FEE = 20;

    data.forEach(item => {
      const matchedBo = businessObjects?.find(bo => bo.id === item.boId);
      const isConnectWithTreatwell = matchedBo?.twConnect?.syncActive || matchedBo?.twPro?.syncActive;

      const liteNormalFee = calculateLiteFee(item, monthRange);

      if (OWNER_TEST.includes(matchedBo?.owner)) {
        item.monthlyFee = 0;
        item.priceDescription = 'Test salon';
      } else if (matchedBo?.version === BUSINESS_OBJECT_VERSION.PRO) {
        if (item.boId > LAST_BO_ID_OLD_PRO_PRICE || OWNER_WITH_NEW_PRICE.includes(matchedBo?.owner)) {
          item.monthlyFee = PRO_FEE + liteNormalFee + (isConnectWithTreatwell ? TW_CONNECT_FEE : 0);
          item.priceDescription =
            `${liteNormalFee}€ appointment fee + ${PRO_FEE}€ PRO fee` +
            (isConnectWithTreatwell ? ` + ${TW_CONNECT_FEE}€ Treatwell connect fee` : '');
        } else if (item.boId > LAST_BO_ID_FREE_LITE_PRICE) {
          item.monthlyFee = OLD_PRO_FEE + (isConnectWithTreatwell ? TW_CONNECT_FEE : 0);
          item.priceDescription =
            `${OLD_PRO_FEE}€ PRO fee` + (isConnectWithTreatwell ? ` + ${TW_CONNECT_FEE}€ Treatwell connect fee` : '');
        } else {
          item.monthlyFee = OLD_PRO_FEE;
          item.priceDescription = `Old PRO price: ${OLD_PRO_FEE}€`;
        }
      } else if (matchedBo?.version === BUSINESS_OBJECT_VERSION.LITE_PLUS) {
        if (
          item.boId > LAST_BO_ID_TWO_YEARS_FREE_LITE_PRICE ||
          (item.boId > LAST_BO_ID_FREE_LITE_PRICE && !isFreeTrialActive) ||
          OWNER_WITH_NEW_PRICE.includes(matchedBo?.owner)
        ) {
          item.monthlyFee = TW_CONNECT_FEE + liteNormalFee;
          item.priceDescription = `${liteNormalFee}€ appointment fee + ${TW_CONNECT_FEE}€ Treatwell connect fee`;
        } else if (item.boId <= LAST_BO_ID_FREE_LITE_PRICE) {
          item.monthlyFee = OLD_LITE_PLUS_FEE;
          item.priceDescription = `Old LITE_PLUS price: ${OLD_LITE_PLUS_FEE}€ (incl. appointment fee and Treatwell connect fee)`;
        } else {
          item.monthlyFee = OLD_LITE_PLUS_FEE - OLD_LITE_FEE;
          item.priceDescription = `Free trial for appointment fee until 31.03.2026. ${
            OLD_LITE_PLUS_FEE - OLD_LITE_FEE
          }€ Treatwell connect fee`;
        }
      } else if (matchedBo?.version === BUSINESS_OBJECT_VERSION.LITE) {
        if (item.boId > LAST_BO_ID_TWO_YEARS_FREE_LITE_PRICE || OWNER_WITH_NEW_PRICE.includes(matchedBo?.owner)) {
          item.monthlyFee = liteNormalFee;
          item.priceDescription = `${liteNormalFee}€ appointment fee`;
        } else if (item.boId > LAST_BO_ID_FREE_LITE_PRICE) {
          if (isFreeTrialActive) {
            item.monthlyFee = 0;
            item.priceDescription = `Free trial for appointment fee until 31.03.2026. After that ${OLD_LITE_FEE}€`;
          } else {
            item.monthlyFee = OLD_LITE_FEE;
            item.priceDescription = `Old LITE fee: ${OLD_LITE_FEE}€`;
          }
        } else {
          item.monthlyFee = 0;
          item.priceDescription = `Free LITE user`;
        }
      }
    });

    return data;
  };

  const updateFilteredBusinessObjectStatistics = (data: BusinessObjectStatistic[]) => {
    const key = searchKeyword?.toLowerCase()?.trim();

    if (!key?.length) {
      setFilteredBusinessObjectStatistics(data);
    } else {
      setFilteredBusinessObjectStatistics(
        data?.filter(
          data =>
            data.boId.toString().includes(key) ||
            data.name.toLowerCase().trim().includes(key) ||
            data.owner.toLowerCase().trim().includes(key)
        )
      );
    }
  };

  const fetchBusinessObjectStatistic = async (monthRange: number) => {
    try {
      setIsLoading(true);

      const businessObjects = await businessObjectService.getAllBusinessObjects();

      const toDate = new Date();
      const fromDate = DateTimeUtils.getFirstDateOfMonth(DateTimeUtils.addMonthToDate(toDate, 1 - monthRange));

      const data = await adminService.getBusinessObjectStatistic(
        DateTimeUtils.formatDateToString(fromDate, DATE_FORMAT.DATE_FORMAT_BACKEND),
        DateTimeUtils.formatDateToString(toDate, DATE_FORMAT.DATE_FORMAT_BACKEND)
      );

      const processedData = addPriceDescription(data, businessObjects, monthRange);

      setBusinessObjectStatistics(processedData);
      updateFilteredBusinessObjectStatistics(processedData);
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateSearchKeywordHandler = (event: any) => {
    const { value } = event.target;
    setSearchKeyword(value);
  };

  const updateRangeHandler = (event: any) => {
    const { value } = event.target;
    setRange(Number(value));
  };

  const selectBoHandler = (data: BusinessObjectStatistic) => {
    setSelectedBusinessObject(data);
    setIsOpenDetailsDialog(true);
  };

  const closeDetailsDialog = () => {
    setIsOpenDetailsDialog(false);
  };

  // use this syntax to trigger function on init
  // React.useEffect(() => {
  //   fetchBusinessObjectStatistic(range);
  // }, []);

  // use this syntax to watch change of range
  React.useEffect(() => {
    fetchBusinessObjectStatistic(range);
  }, [range]);

  // use this syntax to watch change of range
  React.useEffect(() => {
    updateFilteredBusinessObjectStatistics(businessObjectStatistics);
  }, [searchKeyword]);

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3} className={classes.marginBottom}>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            margin="dense"
            type="text"
            fullWidth
            key="keyword"
            label="Search"
            name="keyword"
            value={searchKeyword || ''}
            onChange={updateSearchKeywordHandler}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            variant="outlined"
            margin="dense"
            select
            required
            fullWidth
            label="Range"
            name="range"
            value={range || ''}
            onChange={updateRangeHandler}
          >
            {MONTH_RANGES.map(r => (
              <MenuItem value={r} key={r}>
                Last {r} months
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {isOpenDetailsDialog ? (
        <BusinessObjectStatisticDetailsDialogComponent
          isOpen={isOpenDetailsDialog}
          onClose={closeDetailsDialog}
          boId={selectedBusinessObject?.boId}
          boName={selectedBusinessObject?.name}
          owner={selectedBusinessObject?.owner}
        ></BusinessObjectStatisticDetailsDialogComponent>
      ) : (
        <></>
      )}

      {isLoading ? (
        <LinearProgress />
      ) : (
        <CustomDataTableComponent
          tableConfig={tableConfig}
          data={filteredBusinessObjectStatistics}
          onSelect={selectBoHandler}
        />
      )}
    </>
  );
}
