import React from 'react';
import { Button, Grid, InputAdornment, TextField } from '@material-ui/core';
import CustomDialogComponent from '../../../../../../commons/components/custom-dialog/custom-dialog.component';

interface GatewayApiTokenInputDialogComponentProps {
  isOpen: boolean;
  onClose: Function;
  onSubmit: Function;
}

export default function GatewayApiTokenInputDialogComponent(props: GatewayApiTokenInputDialogComponentProps) {
  const [token, setToken] = React.useState<string>(null);

  const submitHandler = (event: any) => {
    event.preventDefault();
    props.onSubmit(token);
  };

  const getContent = () => {
    return (
      <TextField
        variant="outlined"
        margin="dense"
        type="text"
        required
        fullWidth
        key="token"
        label="GatewayAPI token"
        name="token"
        value={token || ''}
        placeholder="ey..."
        onChange={event => setToken(event.target?.value)}
        InputProps={{
          startAdornment: <InputAdornment position="start">Bearer</InputAdornment>,
        }}
        helperText={<span>Login into GatewayAPI and get the Bearer Token</span>}
      />
    );
  };

  const getButtons = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button fullWidth variant="contained" onClick={() => props.onClose()}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button type="submit" fullWidth variant="contained" color="primary" onClick={submitHandler}>
            Submit
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <CustomDialogComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={'GatewayAPI token'}
      content={getContent()}
      buttons={getButtons()}
    ></CustomDialogComponent>
  );
}
