import { LexofficeContact } from '../../models/interfaces/LexofficeContact.interface';
import { StringUtils } from './string.util';

export class LexofficeContactUtils {
  public static isCompanyNameContainsString(key: string, contact: LexofficeContact): boolean {
    return StringUtils.simplifyString(contact?.company?.name).includes(StringUtils.simplifyString(key));
  }

  public static generateContactPersonAsString(contact: LexofficeContact): string {
    if (!contact?.company?.contactPersons?.length && !contact?.person) {
      return null;
    }

    if (contact?.company?.contactPersons?.length) {
      const firstContactPerson = contact.company?.contactPersons?.[0];
      return `(${firstContactPerson?.salutation}) ${firstContactPerson?.firstName} ${firstContactPerson?.lastName}`;
    }

    return `(${contact?.person?.salutation}) ${contact?.person?.firstName} ${contact?.person?.lastName}`;
  }

  public static isContactPersonContainsString(key: string, contact: LexofficeContact): boolean {
    return StringUtils.simplifyString(this.generateContactPersonAsString(contact)).includes(
      StringUtils.simplifyString(key)
    );
  }

  public static generateBillingAddressAsString(contact: LexofficeContact): string {
    if (!contact?.addresses?.billing?.length) {
      return null;
    }

    const firstAddress = contact.addresses.billing[0];
    return `[${firstAddress.countryCode}] ${firstAddress.street}, ${firstAddress.zip} ${firstAddress.city}`;
  }

  public static isBillingAddressContainsString(key: string, contact: LexofficeContact): boolean {
    return StringUtils.simplifyString(this.generateBillingAddressAsString(contact)).includes(
      StringUtils.simplifyString(key)
    );
  }

  public static generateEmailAsArrayString(contact: LexofficeContact): string[] {
    if (!contact?.emailAddresses?.business?.length && !contact?.emailAddresses?.private?.length) {
      return null;
    }

    const emails: string[] = [];

    contact?.emailAddresses?.business?.forEach(e => {
      emails.push(`(business) ${e}`);
    });

    contact?.emailAddresses?.private?.forEach(e => {
      emails.push(`(private) ${e}`);
    });

    return emails;
  }

  public static isAnyEmailContainsString(key: string, contact: LexofficeContact): boolean {
    const emails = this.generateEmailAsArrayString(contact);
    return !!emails?.find(e => StringUtils.simplifyString(e).includes(StringUtils.simplifyString(key)));
  }

  public static generatePhoneNumberAsArrayString(contact: LexofficeContact): string[] {
    if (!contact?.phoneNumbers?.business?.length && !contact?.phoneNumbers?.private?.length) {
      return null;
    }

    const phones: string[] = [];

    contact?.phoneNumbers?.business?.forEach(e => {
      phones.push(`(business) ${e}`);
    });

    contact?.phoneNumbers?.private?.forEach(e => {
      phones.push(`(private) ${e}`);
    });

    return phones;
  }

  public static isAnyPhoneNumberContainsString(key: string, contact: LexofficeContact): boolean {
    const phones = this.generatePhoneNumberAsArrayString(contact);
    return !!phones?.find(p => StringUtils.simplifyString(p).includes(StringUtils.simplifyString(key)));
  }
}
