import React from 'react';
import { LexofficeConnection } from '../../../../../models/interfaces/LexofficeConnection.interface';
import {
  CustomDataTableConfig,
  HeadCell,
} from '../../../../../commons/components/custom-data-table/custom-data-table-config.model';
import CustomDataTableComponent from '../../../../../commons/components/custom-data-table/custom-data-table.component';

interface LexofficeConnectionTableComponentProps {
  lexofficeConnections: LexofficeConnection[];
  onSelect?: Function;
}

export default function LexofficeConnectionTableComponent(props: LexofficeConnectionTableComponentProps) {
  const headCells: HeadCell[] = [
    { id: 'id', field: 'id', label: 'ID' },
    { id: 'userId', field: 'userId', label: 'User id' },
    { id: 'userEmail', field: 'userEmail', label: 'User email' },
    { id: 'businessObjectId', field: 'businessObjectId', label: 'Salon ID' },
    { id: 'businessObjectName', field: 'businessObjectName', label: 'Salon name' },
    { id: 'contactName', field: 'contactName', label: 'Contact name' },
    { id: 'customerNumber', field: 'customerNumber', label: 'Customer number' },
    { id: 'taxType', field: 'taxType', label: 'Tax type' },
    { id: 'language', field: 'language', label: 'Billing language' },
  ];

  const tableConfig: CustomDataTableConfig = {
    tableName: `Lexoffice connections`,
    rowsPerPage: 10,
    headCells,
  };

  return (
    <CustomDataTableComponent tableConfig={tableConfig} data={props?.lexofficeConnections} onSelect={props?.onSelect} />
  );
}
