import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DescriptionIcon from '@material-ui/icons/Description';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import PersonIcon from '@material-ui/icons/Person';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import PeopleIcon from '@material-ui/icons/People';
import StorefrontIcon from '@material-ui/icons/Storefront';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import LinkIcon from '@material-ui/icons/Link';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { PRIVATE_ROUTE, PUBLIC_ROUTE } from '../../routes/route-definition.model';
import { PRIVATE_FRAME_ROUTE } from './routing/private-frame-route-definition.model';

import authenticationService from '../../services/auth.service';
import { createRoute, getParamsFromRoute, isMatchedRoute } from '../../commons/utils/route-helper';
import PrivateRoutingComponent from './routing/private-routing.component';
import { BUSINESS_OBJECT_ROUTE } from './pages/business-object-context/routing/business-object-route-definition.model';
import { USER_ROUTE } from './pages/user-context/routing/user-route-definition.model';
import { CATEGORY_ROUTE } from './pages/category-context/routing/category-route-definition.model';
import { PRODUCT_ROUTE } from './pages/product-context/routing/product-route-definition.model';
import { Book, CollectionsBookmark } from '@material-ui/icons';
import { BUSINESS_OBJECT_DETAIL_ROUTE } from './pages/business-object-context/pages/business-object-detail-context/routing/business-object-detail-route-definition.model';
import { CATEGORY_DETAIL_ROUTE } from './pages/category-context/pages/category-detail-context/routing/category-detail-route-definition.model';
import userService from '../../services/user.service';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

interface RouteWithIcon {
  path: string;
  name?: string;
  icon?: any;
  hidden?: boolean;
}

export default function PrivateComponent(props: any) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [navigationGroup, setNavigationGroup] = React.useState<RouteWithIcon[]>([]);

  React.useEffect(() => {
    fetchAndCheckUserAccess();
  }, []);

  const fetchAndCheckUserAccess = async () => {
    const user = await userService.getCurrentLoggedInUser();

    if (!authenticationService.canUserAccessApp(user)) {
      authenticationService.logout();
      props.history.push(PUBLIC_ROUTE.AUTH_PROCESS);
    }
  };

  const redirect = (path: string) => {
    props.history.push(path);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    authenticationService.logout();
    props.history.push(PUBLIC_ROUTE.AUTH_PROCESS);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCurrentRoute = (): string => {
    return props.location.pathname;
  };

  const accountGroup: RouteWithIcon[] = [
    {
      name: 'Account',
      path: PRIVATE_FRAME_ROUTE.ACCOUNT,
      icon: <PersonIcon />,
    },
    {
      name: 'Password',
      path: PRIVATE_FRAME_ROUTE.ACCOUNT_PASSWORD,
      icon: <VerifiedUserIcon />,
    },
  ];

  const [managementGroup, setManagementGroup] = React.useState<RouteWithIcon[]>([
    {
      name: 'Dashboard',
      path: PRIVATE_FRAME_ROUTE.STATISTIC,
      icon: <EqualizerIcon />,
    },
    {
      name: 'User Watchlist',
      path: PRIVATE_FRAME_ROUTE.USER_WATCHLIST,
      icon: <VisibilityIcon />,
    },
    {
      name: 'Users',
      path: PRIVATE_FRAME_ROUTE.USER,
      icon: <PeopleIcon />,
    },
    {
      path: USER_ROUTE.USER_DETAIL,
    },
    {
      path: USER_ROUTE.USER_CREATE,
      hidden: true,
    },
    {
      path: PRIVATE_ROUTE.FRAME,
      hidden: true,
    },
    {
      path: BUSINESS_OBJECT_ROUTE.BUSINESS_OBJECT_DETAIL,
    },
    {
      path: BUSINESS_OBJECT_ROUTE.BUSINESS_OBJECT_CREATE,
      hidden: true,
    },
    {
      path: BUSINESS_OBJECT_DETAIL_ROUTE.CATEGORY,
      hidden: true,
    },
    {
      path: CATEGORY_ROUTE.MASSIVE_CREATE,
      hidden: true,
    },
    {
      path: CATEGORY_ROUTE.CATEGORY_DETAIL,
    },
    {
      path: CATEGORY_ROUTE.CATEGORY_CREATE,
      hidden: true,
    },
    {
      path: CATEGORY_DETAIL_ROUTE.PRODUCT,
      hidden: true,
    },
    {
      path: PRODUCT_ROUTE.PRODUCT_DETAIL,
    },
    {
      path: PRODUCT_ROUTE.PRODUCT_CREATE,
      hidden: true,
    },
    {
      name: 'Credit package orders',
      path: PRIVATE_FRAME_ROUTE.CREDIT_PACKAGE_ORDER,
      icon: <ShoppingBasketIcon />,
    },
    {
      name: 'System credit',
      path: PRIVATE_FRAME_ROUTE.SYSTEM_WALLET,
      icon: <AccountBalanceWalletIcon />,
    },
    {
      name: 'Pricing settings',
      path: PRIVATE_FRAME_ROUTE.CREDIT_PACKAGE_MANAGEMENT,
      icon: <AttachMoneyIcon />,
    },
    {
      name: 'Service tag settings',
      path: PRIVATE_FRAME_ROUTE.SERVICE_TAG_MANAGEMENT,
      icon: <LocalOfferIcon />,
    },
    {
      name: 'Shortened link',
      path: PRIVATE_FRAME_ROUTE.SHORTENED_LINK,
      icon: <LinkIcon />,
    },
    {
      name: 'Import customer',
      path: PRIVATE_FRAME_ROUTE.IMPORT_CUSTOMER_CSV,
      icon: <DescriptionIcon />,
    },
    {
      name: 'Lexoffice',
      path: PRIVATE_FRAME_ROUTE.LEXOFFICE,
      icon: <img src="images/lexoffice.png" style={{ width: '24px' }} />,
    },
  ]);

  const getMatchedRouteFromAccountGroup = (): RouteWithIcon => {
    const currentRoute = getCurrentRoute();
    return accountGroup.find(route => isMatchedRoute(route.path, currentRoute));
  };

  const getMatchedRouteFromManagementGroup = (): RouteWithIcon => {
    const currentRoute = getCurrentRoute();
    return managementGroup.find(route => isMatchedRoute(route.path, currentRoute));
  };

  const getNavigationGroup = (): RouteWithIcon[] => {
    if (getMatchedRouteFromAccountGroup()) {
      return accountGroup;
    }
    if (getMatchedRouteFromManagementGroup()) {
      return managementGroup;
    }
    return [];
  };

  React.useEffect(() => {
    const orderedRoutes: RouteWithIcon[] = [
      {
        path: USER_ROUTE.USER_DETAIL,
        icon: <PersonIcon />,
      },
      {
        path: BUSINESS_OBJECT_ROUTE.BUSINESS_OBJECT_DETAIL,
        icon: <StorefrontIcon />,
      },
      {
        path: CATEGORY_ROUTE.CATEGORY_DETAIL,
        icon: <CollectionsBookmark />,
      },
      {
        path: PRODUCT_ROUTE.PRODUCT_DETAIL,
        icon: <Book />,
      },
    ];

    const currentRoute = getCurrentRoute();

    const matchedIndex = orderedRoutes.findIndex(route => isMatchedRoute(route.path, currentRoute));
    const matchedRoute = orderedRoutes.find(route => isMatchedRoute(route.path, currentRoute));

    let params: any;

    if (matchedRoute) {
      params = getParamsFromRoute(currentRoute, matchedRoute.path);

      const { userId, boId, categoryId, productId } = params;

      const pathNames = [
        userId ? `User ${userId}` : null,
        boId ? `Business object ${boId}` : null,
        categoryId ? `Category ${categoryId}` : null,
        productId ? `Product ${productId}` : null,
      ];

      pathNames.forEach((name, index) => {
        if (name) {
          orderedRoutes[index].name = name;
        }
      });
    }

    const newManagementGroup: RouteWithIcon[] = [...managementGroup];

    orderedRoutes.forEach((route, index) => {
      if (index <= matchedIndex) {
        newManagementGroup.forEach(mRoute => {
          if (!mRoute.hidden && isMatchedRoute(mRoute.path, route.path)) {
            mRoute.icon = route.icon;
            mRoute.name = route.name;
            mRoute.path = createRoute(route.path, params);
          }
        });
      } else {
        newManagementGroup.forEach(mRoute => {
          if (isMatchedRoute(mRoute.path, route.path)) {
            mRoute.icon = null;
            mRoute.name = null;
          }
        });
      }
    });

    setManagementGroup(newManagementGroup);

    setNavigationGroup(getNavigationGroup());
  }, [props.location.pathname]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>

          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Super admin
          </Typography>

          <IconButton aria-controls="customized-menu" aria-haspopup="true" color="inherit" onClick={handleClick}>
            <AccountCircleIcon fontSize="large" />
          </IconButton>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem
              selected={!!getMatchedRouteFromManagementGroup()}
              onClick={() => {
                redirect(managementGroup[0].path);
              }}
            >
              <ListItemIcon>
                <DashboardIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </StyledMenuItem>

            <StyledMenuItem
              selected={!!getMatchedRouteFromAccountGroup()}
              onClick={() => {
                redirect(accountGroup[0].path);
              }}
            >
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Account" />
            </StyledMenuItem>

            <StyledMenuItem onClick={logout}>
              <ListItemIcon>
                <PowerSettingsNewIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </StyledMenuItem>
          </StyledMenu>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>

        <Divider />

        <List>
          {navigationGroup
            .filter(routeWithIcon => !!routeWithIcon.icon && !!routeWithIcon.name)
            .map(routeWithIcon => (
              <ListItem
                button
                key={routeWithIcon?.name?.toLowerCase().split(' ').join('-')}
                onClick={() => {
                  redirect(routeWithIcon.path);
                }}
                selected={getCurrentRoute() === routeWithIcon.path}
              >
                <ListItemIcon>{routeWithIcon.icon}</ListItemIcon>
                <ListItemText primary={routeWithIcon.name} />
              </ListItem>
            ))}
        </List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <PrivateRoutingComponent {...props} />
      </main>
    </div>
  );
}
