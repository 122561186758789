import React from 'react';
import {
  CustomDataTableConfig,
  HeadCell,
} from '../../../../../../commons/components/custom-data-table/custom-data-table-config.model';
import { LOCALE } from '../../../../../../models/enums/Locale.enum';
import CustomDataTableComponent from '../../../../../../commons/components/custom-data-table/custom-data-table.component';
import { BulkgateWalletInfoData } from '../../../../../../models/interfaces/BulkgateWalletInfoData.interface';

interface SmsProviderPriceTableComponentProps {
  bulkgateWalletInfoData?: BulkgateWalletInfoData;
}

interface BulkgatePriceData {
  id: number;
  locale: LOCALE;
  minCreditPerSms: number;
  maxCreditPerSms: number;
  estimatedCreditPerSms: number;
  estimatedAvailableSms: number;
}

export default function BulkgatePriceTableComponent(props: SmsProviderPriceTableComponentProps) {
  const headCells: HeadCell[] = [
    { id: 'locale', field: 'locale', label: 'Locale' },
    { id: 'minCreditPerSms', field: 'minCreditPerSms', label: 'Min credit per SMS' },
    { id: 'maxCreditPerSms', field: 'maxCreditPerSms', label: 'Max credit per SMS' },
    { id: 'estimatedCreditPerSms', field: 'estimatedCreditPerSms', label: 'Estimated credit per SMS' },
    { id: 'estimatedAvailableSms', field: 'estimatedAvailableSms', label: 'Estimated available SMS' },
  ];

  const tableConfig: CustomDataTableConfig = {
    tableName: `Bulkgate: ${props?.bulkgateWalletInfoData?.credit} ${props?.bulkgateWalletInfoData?.currency}`,
    rowsPerPage: 10,
    headCells,
  };

  const estimatedCreditPerSmsDe = 1.9;
  const estimatedCreditPerSmsCh = 0.71;

  const estimatedAvailableSmsDe = Math.floor((props?.bulkgateWalletInfoData?.credit || 0) / estimatedCreditPerSmsDe);
  const estimatedAvailableSmsCh = Math.floor((props?.bulkgateWalletInfoData?.credit || 0) / estimatedCreditPerSmsCh);

  const data: BulkgatePriceData[] = [
    {
      id: 1,
      locale: LOCALE.de_DE,
      minCreditPerSms: 1.75,
      maxCreditPerSms: 1.9,
      estimatedCreditPerSms: estimatedCreditPerSmsDe,
      estimatedAvailableSms: estimatedAvailableSmsDe,
    },
    {
      id: 2,
      locale: LOCALE.de_CH,
      minCreditPerSms: 0.47,
      maxCreditPerSms: 1.18,
      estimatedCreditPerSms: estimatedCreditPerSmsCh,
      estimatedAvailableSms: estimatedAvailableSmsCh,
    },
  ];

  return <CustomDataTableComponent tableConfig={tableConfig} data={data} />;
}
