import React from 'react';
import { Switch } from 'react-router-dom';

import { PRIVATE_FRAME_ROUTE } from './private-frame-route-definition.model';
import PrivateRoute from '../../../routes/private-route.component';
import ScrollToTop from '../../../commons/utils/scroll-to-top';
import AccountDetailComponent from '../pages/account/account-detail/account-detail.component';
import AccountPasswordComponent from '../pages/account/account-password/account-password.component';
import UserContextComponent from '../pages/user-context/user-context.component';
import ImportCustomerCsvComponent from '../pages/import-customer-csv/import-customer-csv.component';
import SystemSmsBudgetComponent from '../pages/system-sms-budget/system-sms-budget.component';
import CreditPackageManagementComponent from '../pages/credit-package-management/credit-package-management.component';
import StatisticComponent from '../pages/statistic/statistic.component';
import ServiceTagSettingsComponent from '../pages/service-tag-settings/service-tag-settings.component';
import CreditPackageOrderComponent from '../pages/credit-package-order/credit-package-order.component';
import ShortenedLinkComponent from '../pages/shortened-link/shortened-link.component';
import UserWatchlistComponent from '../pages/user-watchlist/user-watchlist.component';
import LexofficePageComponent from '../pages/lexoffice-page/lexoffice-page.component';

export default function PrivateRoutingComponent(props: any) {
  return (
    <ScrollToTop>
      <Switch>
        <PrivateRoute {...props} path={PRIVATE_FRAME_ROUTE.ACCOUNT} component={AccountDetailComponent} />
        <PrivateRoute {...props} path={PRIVATE_FRAME_ROUTE.ACCOUNT_PASSWORD} component={AccountPasswordComponent} />
        <PrivateRoute {...props} path={PRIVATE_FRAME_ROUTE.STATISTIC} component={StatisticComponent} />
        <PrivateRoute {...props} path={PRIVATE_FRAME_ROUTE.USER} component={UserContextComponent} />
        <PrivateRoute
          {...props}
          path={PRIVATE_FRAME_ROUTE.IMPORT_CUSTOMER_CSV}
          component={ImportCustomerCsvComponent}
        />
        <PrivateRoute
          {...props}
          path={PRIVATE_FRAME_ROUTE.CREDIT_PACKAGE_ORDER}
          component={CreditPackageOrderComponent}
        />
        <PrivateRoute {...props} path={PRIVATE_FRAME_ROUTE.SYSTEM_WALLET} component={SystemSmsBudgetComponent} />
        <PrivateRoute
          {...props}
          path={PRIVATE_FRAME_ROUTE.CREDIT_PACKAGE_MANAGEMENT}
          component={CreditPackageManagementComponent}
        />
        <PrivateRoute
          {...props}
          path={PRIVATE_FRAME_ROUTE.SERVICE_TAG_MANAGEMENT}
          component={ServiceTagSettingsComponent}
        />
        <PrivateRoute {...props} path={PRIVATE_FRAME_ROUTE.SHORTENED_LINK} component={ShortenedLinkComponent} />
        <PrivateRoute {...props} path={PRIVATE_FRAME_ROUTE.USER_WATCHLIST} component={UserWatchlistComponent} />
        <PrivateRoute {...props} path={PRIVATE_FRAME_ROUTE.LEXOFFICE} component={LexofficePageComponent} />
        <PrivateRoute {...props} component={StatisticComponent} />
      </Switch>
    </ScrollToTop>
  );
}
